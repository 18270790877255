@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&family=Playfair+Display:wght@400;500;600;700;800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  /* font-family: 'Montserrat', sans-serif; */
  font-family: 'Playfair Display', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@layer utilities {
  .web_padding_x {
    @apply px-6 md:px-10 lg:px-14 2xl:px-[10vw];
  }
  .web_padding_l {
    @apply pl-4 md:pl-10 lg:pl-14 2xl:pl-[10vw];
  }
  .web_padding_t {
    @apply pt-10 md:pt-20 lg:pt-20 2xl:pt-24;
  }
  .web_padding_y {
    @apply py-10 md:py-20 lg:py-14 tall:py-20 2xl:py-24;
  }
  .center_y {
    @apply top-[50%] translate-y-[-50%];
  }
  .center_x {
    @apply left-[50%] translate-x-[-50%];
  }
  .center_content {
    @apply flex justify-center items-center;
  }
  .web_heading {
    @apply font-bold text-2xl md:text-4xl lg:text-[24px] xl:text-4xl 2xl:leading-[56px] 2xl:text-[42px] text-black;
  }
  .web_heading_2 {
    @apply font-bold text-2xl md:text-[32px] 2xl:leading-[56px] text-black;
  }
  /* .web_heading2 {
    @apply font-bold text-2xl md:text-4xl md:leading-[56px] xl:text-[42px] text-black
  } */
  .bar_above {
    @apply relative mt-2 before:bottom-[calc(100%+6px)] before:rounded-lg before:absolute before:content-[''] before:w-[74px] before:left-0 before:bg-black before:h-[7px] rounded;
  }
  .web_paragraph_1 {
    @apply 2xl:text-lg text-black;
  }

  .about_layout {
    @apply mt-10 nottall:!mt-0 tall:mt-[100px] grid items-center gap-[70px] lg:grid-cols-[40%_1fr];
  }
  .about_privacy {
    @apply mt-10 nottall:!mt-0 tall:mt-[100px]  items-center gap-[70px] lg:grid-cols-[40%_1fr] 2xl:grid-cols-[30%_1fr];
  }

  .about_layout_content {
    @apply px-4 lg:pr-0 md:px-10 lg:pl-14 2xl:pl-[10vw];
  }

  .about_layout_privacy {
    @apply px-4 lg:pr-0 md:px-10 lg:px-14 2xl:px-[10vw];
  }

  .web_custom_layout_1 {
    @apply grid items-center gap-y-10 lg:grid-cols-[1fr_40%] lg:gap-x-10 xl:gap-x-20;
  }

  .custom_layout_img_1 {
    @apply w-[70%] lg:order-1 max-w-[350px] lg:max-w-[unset] 2xl:w-[470px] mx-auto block aspect-square;
  }

  .dashboard_subtitle {
    /* font size reduced here */
    @apply text-base sm:text-[20px] font-bold relative w-fit after:content-[''] after:absolute after:w-[84px] mb-10 after:rounded after:h-1 after:bg-[#EEEEEE] after:bottom-[-12px] after:left-0;
  }
}

/* .szh-menu */
.campaign_sort .szh-menu {
  min-width: 0 !important;
  padding: 0 !important;
}

.campaign_sort .szh-menu__item {
  padding: 10px 26px !important;
  font-weight: 500;
  color: #000;
  display: flex;
  justify-content: center;
}

.campaign_sort .szh-menu__item.active_sort {
  background-color: #dedede;
}

.campaign_sort ul {
  border: 1px solid #dedede !important;
}

.campaign_brand_menu ul {
  @apply pt-5 md:pt-5 px-4 md:px-6 pb-4 w-[90vw] max-w-[400px];
}

.campaign_price_menu ul {
  @apply pt-5 md:pt-5 px-4 md:px-6 pb-4 w-[90vw] max-w-[400px];
}

.account_logout_menu ul {
  @apply rounded-lg font-bold text-center min-w-[8rem];
}

.account_logout_menu ul li {
  @apply justify-center;
}

/* nav menu */
.nav_flag_menu ul {
  background-color: unset !important;
  box-shadow: none !important;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-top: 10px !important;
  min-width: 0 !important;
}
.nav_flag_menu ul li {
  background-color: unset !important;
  padding: 0 !important;
}

.admin_nav.active,
.admin_nav:hover {
  position: relative;
}

.admin_nav.active::before,
.admin_nav:hover::before {
  content: '';
  position: absolute;
  height: 4px;
  background-color: white;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 4px;
  @apply w-6 2xl:w-8;
}

.campaign_dotted_line_with_circles {
  @apply absolute h-[70px] top-[50%] -translate-y-[50%] border-l-2 border-dashed border-l-black;
}
.campaign_dotted_line_with_circles::before {
  @apply content-[''] absolute top-0 left-[50%] -translate-x-[calc(50%+1px)] -translate-y-[100%] rounded-full w-2.5 h-2.5 border-2 border-black border-solid;
}
.campaign_dotted_line_with_circles .dot_above {
  @apply absolute top-0 whitespace-nowrap text-center font-bold text-xs left-[50%] -translate-x-[calc(50%+1px)] -translate-y-[calc(100%+10px)];
}
.campaign_dotted_line_with_circles::after {
  @apply content-[''] absolute bottom-0 left-[50%] -translate-x-[calc(50%+1px)] translate-y-[100%] rounded-full w-2.5 h-2.5 border-2 border-black border-solid;
}
.campaign_dotted_line_with_circles .dot_below {
  @apply absolute bottom-0 whitespace-nowrap text-center font-bold text-xs left-[50%] -translate-x-[calc(50%+1px)] translate-y-[calc(100%+10px)];
}

.slanted_bars {
  background: linear-gradient(
    -45deg,
    black 20%,
    transparent 20%,
    transparent 40%,
    black 40%,
    black 60%,
    transparent 60%,
    transparent 80%,
    black 80%
  );
  background-size: 40px 60px;
}

.campaign_single_nav.active {
  border-bottom-color: black;
}

/* carousels */
.home_carousel .control-arrow {
  top: 50% !important;
  transform: translateY(-50%) !important;
  color: #000 !important;
  background-color: white !important;
  width: 30px !important;
  height: 30px !important;
  border-radius: 50% !important;
  font-size: 26px;
  bottom: unset !important;
  margin-top: 0;
  padding: 0 !important;
  font-weight: 800;
  opacity: unset !important;
  font-size: 24px !important;
}
.home_carousel .control-arrow:hover {
  background-color: #d3d3d3 !important;
}

.home_carousel .control-arrow::before {
  content: '' !important;
  position: absolute;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  background-size: cover !important;
  width: 90% !important;
  height: 90% !important;
  background-position: center !important;
  margin: 0 !important;
  display: block !important;
  border-top: none !important;
  border-bottom: none !important;
  border-left: none !important;
  border-right: none !important;
}

.carousel.carousel-slider {
  overflow: visible !important;
}

.home_carousel .control-arrow.control-prev {
  left: -82% !important;
  /* z-index: 1000000 !important; */
}
@media screen and (max-width: 767px) {
  .home_carousel .control-arrow.control-prev {
    left: 2px !important;
  }
}
.home_carousel .control-arrow.control-next {
  right: 2px !important;
}

.home_carousel .control-arrow.control-prev::before {
  background-image: url(assets/icons/angle-left.png);
}
.home_carousel .control-arrow.control-next::before {
  background-image: url(assets/icons/angle-right.png);
}

.shop_item_carousel .control-dots {
  position: absolute;
  right: 0 !important;
  bottom: 0 !important;
  top: 0 !important;
  margin: 0 10px !important;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  padding: 0;
  text-align: center;
  width: unset !important;
  z-index: 1;
}

/* slider */
.MuiSlider-root {
  color: black !important;
}

/* select */
.custom_select__control {
  border-color: black !important;
  border-radius: 24px !important;
  box-shadow: none !important;
}

.custom_select__control--is-focused {
  box-shadow: 0 0 0 1px black !important;
}

.custom_select__indicator-separator {
  display: none !important;
}

.custom_select__indicator {
  background-color: black !important;
  color: white !important;
  border-radius: 50% !important;
  width: 30px;
  height: 30px;
  position: relative;
  right: 6px;
  padding: 0 !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.expand-icon-accordion {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.expand-icon-accordion:before {
  content: '';
  width: 5px;
  height: 5px;
  border-left: 2px solid white;
  border-bottom: 2px solid white;
  transform: rotate(-45deg);
}

.admin_size_input > div {
  border-radius: 20px !important;
}
.admin_size_input input {
  padding: 6px 20px !important;
}

.admin_input_1 input {
  padding: 16px 16px !important;
  box-sizing: border-box !important;
}
.admin_input_1 > div {
  border-radius: 20px !important;
}

.admin_input_1.admin_textarea > div {
  border-radius: 10px !important;
}

@media screen and (min-width: 768px) {
  .admin_input_1 input {
    padding: 20px 32px !important;
  }
  .admin_input_1 > div {
    border-radius: 27px !important;
  }
}

.react-tel-input .flag-dropdown {
  background-color: white !important;
  border: 1px solid black !important;
  border-radius: 27px 0 0 27px !important;
}

.react-tel-input .selected-flag {
  outline: none;
  position: relative;
  width: 38px;
  height: 100%;
  padding: 0 0 0 8px;
  border-radius: 27px 0 0 27px !important;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background: #fff;
  border-radius: 27px 0 0 0 !important;
}

/* input field on profile page */
.block_input {
  width: 100%;
}

.block_input > div {
  display: block;
  margin-top: 12px;
}

/* Chrome, Safari, Edge, Opera */
input.hide_arrows::-webkit-outer-spin-button,
input.hide_arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.hide_arrows[type='number'] {
  -moz-appearance: textfield;
}

.szh-accordion__item--expanded .szh-accordion__item-heading {
  margin-bottom: 16px !important;
}
